import { useState, forwardRef } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import { LoadingModal } from "components/loadingOrder/loading";
import Checkbox from "components/ui-kit/Checkbox";

import { validatePayment } from "utils/validation";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../scss/settings.module.scss";

import LiqpayConfigurator from "./LiqpayConfigurator";

import { PAYMENT_TYPE } from "constants/schemes";

const PaymentEditForm = ({
    data,
    view,
    onSubmit,
    onSubmitUpdate,
    handleSubmitClose,
    updateFormPayment,
}, ref) => {
    const [error, setErrors] = useState({
        title: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Додати';
    let title = data?.id ? `Редагування ${data.title}` : "Створення нового";

    const handlerSubmit = () => {
        validatePayment({
            data: {
                ...data
            },

            onSuccess: async () => {
                if (data?.id) {
                    onSubmitUpdate(data?.id);
                } else {
                    onSubmit();
                }

                setErrors({
                    title: "",
                });
            },

            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.row_mrt} ref={ref}>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={styles.wr_drop}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.title}
                    isStyle={true}
                    error={error.title}
                    onChange={(e) => updateFormPayment("title", e.target.value)}
                />
                <br />
                <Radio
                    colors={"#000"}
                    items={PAYMENT_TYPE}
                    label="Тип оплати"
                    valueKey="payment_type"
                    value={data.payment_type}
                    onChange={(e) => updateFormPayment("payment_type", e.value.value)}
                />
                <br />
                <br />
                <Checkbox
                    title="Без виторгу"
                    onChecked={() => updateFormPayment("free", +(!data.free))}
                    checked={!!data.free}
                    isStyle={true}
                />
                <br />
                <Checkbox
                    title="Працює як знижка"
                    onChecked={() => updateFormPayment("is_discount", +(!data.is_discount))}
                    checked={!!data.is_discount}
                    isStyle={true}
                />
                <br />
                <Checkbox
                    title="Активна"
                    onChecked={() => updateFormPayment("status", +(!data.status))}
                    checked={!!data.status}
                    isStyle={true}
                />
                <br />
                {data?.code === "liqpay" && <LiqpayConfigurator />}
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handlerSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>

            {
                view.isLoadEditPayment && (
                    <LoadingModal />
                )
            }
        </div >
    )
}

export const PaymentEdit = forwardRef(PaymentEditForm);
