import produce from "immer";

import {
  ACTIVE_ORDERS_CALLCENTER,
  ACTIVE_ORDERS_KITCHEN,
  ACTIVE_ORDERS_COURIER,
  UPDATE_FORM_SEARCH_ORDERS,
  UPDATE_STATUS_SEARCH,
  UPDATE_LOAD_PAGE,
  UPDATE_FILTER_PAGE_KITCHEN,
} from "../constants/listings";

import { LOGOUT, LOGOUT_BUTTON } from "store/constants/session";

const initialState = {
  orders: {
    new: [],
    cooking: {},
    for_delivery: {},
    pending: {},
    pending_confirmed: {},
    rejected: {},
    closed: {},
    delivered: {},
  },
  ordersCount: { 
    new: 0,
    cooking: 0,
    for_delivery: 0,
    pending: 0,
    pending_confirmed: 0,
    rejected: 0,
    closed: 0,
    delivered: 0,
    waiting_payment: 0,
  },
  ordersCourier: [],
  ordersKitchen: null,
  loadPage: false,
  searchValue: "",
  statusSearch: 0,
  filter: {
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      }
    ]
  },
};

export default produce((draft, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_FILTER_PAGE_KITCHEN: {
      draft.filter[payload.item] = payload.data;
      break;
    }

    case UPDATE_LOAD_PAGE: {
      draft.loadPage = payload;
      break;
    }
    case UPDATE_STATUS_SEARCH: {
      draft.statusSearch = payload;
      break;
    }

    case UPDATE_FORM_SEARCH_ORDERS: {
      draft.searchValue = payload;
      break;
    }

    case ACTIVE_ORDERS_CALLCENTER: {
      draft.orders = {
        ...draft.orders,
        ...payload.orders,
      };
      draft.ordersCount = payload.ordersCount;
      break;
    }

    case ACTIVE_ORDERS_COURIER: {
      draft.ordersCourier = payload.data;
      break;
    }

    case ACTIVE_ORDERS_KITCHEN: {
      draft.ordersKitchen = payload.data;
      break;
    }

    case LOGOUT:
    case LOGOUT_BUTTON:
      return initialState;

    default:
      break;
  }
}, initialState);
