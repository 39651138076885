import { defaultTo, find } from 'lodash';

export const assignableOrderStatuses = [
    { value: 'cooking', title: 'Готується' },
    { value: 'preparing', title: 'Пакується' },
    { value: 'for_delivery', title: 'Очікує доставки' },
    { value: 'on_way', title: 'Доставляється' },
    { value: 'delivered', title: 'Доставлено' },
    { value: 'pending_confirmed', title: 'Попереднє (оброблене)' },
    { value: 'waiting_payment', title: 'Очікує оплати' },
]

export const getOrderStatusName = (value) => defaultTo(
    find(assignableOrderStatuses, { value })?.title, 
    'Невідомо',
);
