import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { PaymentsTable } from "../elements/PaymentsTable";
import { Discounts } from "../elements/Discounts";
import Input from "components/ui-kit/Modals/Input";
import ModalDelete from "../../ModalDelete";

import { statusDraft } from "constants/statuses";

import styled from "styled-components";

import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";

import {
  RADIO_SCHEME_PROMOCODE_OR_PROMOACTION,
} from "constants/schemes";

// actions
import { getPromoactions } from "store/actions/promos";

// redux
import { connect } from "react-redux";

import {
  updateOrderData,
  auditPropmocode,
  removeOrderPromocode,
  updateOrderPaymentsServer
} from "store/actions/order";

// проверка на наличия оплаты наличкой или бонусми
const isCase = (array) => {
  let status = array?.find((item) => {
    if (item?.payment_type == "cash") {
      return true;
    }
  });

  return status;
}

const TabPaymentsComponent = ({
  isEdit,
  isPaymentOk,
  isLiqpayError,
  getPromoactions,
}) => {
  const dispatch = useDispatch();
  const [updatePayments, setUpdatePayments] = useState({
    status: false,
    load: false,
  });

  useEffect(() => {
    getPromoactions();
  }, []);

  const {
    order: {
      data: order
    },
    promos: { promoactions }
  } = useSelector((state) => state);

  const isFree = order.payments.find(el => el.free == 1);
  const isUpdatePayment = order.payments.find(item => ((item.payment_type == "online") && (order.status != statusDraft)));
  const change = isCase(order.payments);

  const handleAuditDiscount = () => {
    dispatch(auditPropmocode());
  }

  const openAndCloseModalSavePayments = () => {
    setUpdatePayments({
      status: false,
      load: false,
    });
  }

  const handlerUpdatePaymentsServer = () => {
    dispatch(updateOrderPaymentsServer(setUpdatePayments));
  }

  const promoType = useMemo(() => {
    return order?.promo_type;
  }, [order]);

  const promoActionOptions = useMemo(() => {
    return promoactions.map(({ id, title }) => ({
      label: title,
      value: id,
    }));
  }, [promoactions]);

  const selectedPromoAction = useMemo(() => {
    return promoActionOptions.find(({ value }) => {
      return value === order?.promocode;
    });
  }, [promoActionOptions, order]);

  return (
    <Wrapper>
      {
        !isFree && (
        <Wrapper>
          <WrDiscount>
            <Radio
              colors={"#fff"}
              items={RADIO_SCHEME_PROMOCODE_OR_PROMOACTION}
              valueKey="typePromocodeOrPromoAction"
              label="Тип промоакції"
              value={promoType}
              onChange={(e) => {
                dispatch(updateOrderData("promo_type", e.value.value))
                dispatch(updateOrderData("promocode", "")) // reset promocode
              }}
            />
          </WrDiscount>

          {promoType == RADIO_SCHEME_PROMOCODE_OR_PROMOACTION[0].value && (<WrDiscount>
            <Input
              title='Промокод'
              type='text'
              width="73.6%"
              onChange={(e) =>
                dispatch(updateOrderData("promocode", e.target.value))
              }
              disabled={!isEdit}
              value={order?.promocode || ''}
            />
            <BtnDiscount>
              <ButtonIcon
                title='Перевірка'
                onClick={handleAuditDiscount}
                disabled={isEdit ? (order?.promocode && order.length < 6) && true : true}
              />
            </BtnDiscount>
          </WrDiscount>)}

          {promoType == RADIO_SCHEME_PROMOCODE_OR_PROMOACTION[1].value && (<WrDiscount>
            <Select
              selectOptions={promoActionOptions}
              selectedOption={selectedPromoAction}
              valueKey='promoAction'
              label='Промо-акція'
              onChange={({ value: { value } }) => {
                dispatch(updateOrderData("promocode", value))
              }}
              noMargin
            />
            <BtnDiscount>
              <ButtonIcon
                title='Перевірка'
                onClick={handleAuditDiscount}
                disabled={isEdit ? (order?.promocode && order.length < 6) && true : true}
              />
            </BtnDiscount>
          </WrDiscount>)}
        </Wrapper>)}
      <PaymentsTable
        isEdit={isEdit}
        isFree={isFree}
        isUpdatePayment={isUpdatePayment}
      />
      {
        isArray(order.discounts) && (order.discounts.length > 0) && (
          <>
            <Titles>Акції, промокоди, знижки</Titles>
            <Discounts isEdit={isEdit} removeOrderPromocode={removeOrderPromocode} />
          </>
        )
      }
      {
        !isPaymentOk && !isLiqpayError && (
          <Error>ПОМИЛКА...<br />Перевірте розрахунок коштів</Error>
        )
      }
      {
        isLiqpayError && (
          <Error>ПОМИЛКА...<br />Перевірте налаштування Liqpay</Error>
        )
      }
      {
        isFree && (
          <Error>Для цього типу оплати замовлення безкоштовне</Error>
        )
      }
      {
        change && (
          <Input
            title={`Решта з ${change ? `${change.sum} грн` : ''}`}
            type='text'
            disabled={true}
            value={`${order?.client_rest ? order?.client_rest : 0} грн`}
          />
        )
      }

      <ModalDelete
        title={'Попередження'}
        text={`Ви впевнені, що хочете змінити оплату ?`}
        isOpen={updatePayments.status}
        isLoad={updatePayments.load}
        funDelete={handlerUpdatePaymentsServer}
        updateModalDelete={openAndCloseModalSavePayments}
      />
    </Wrapper>
  );
};

export const TabPayments = connect(null, {
  getPromoactions,
})(TabPaymentsComponent);

const Titles = styled.div`
  margin: 10px 0;
  color: #EDA240;
`;

const Wrapper = styled.div``;

const WrDiscount = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const BtnDiscount = styled.div`
  margin-left: 10px;
  margin-bottom: 3px;
  button {
    padding-top: 9px;
    padding-bottom: 9px;
    span {
      margin: 0;
    }
  }
`;

const Error = styled.div`
  color: red;
  margin: 10px;
`;
