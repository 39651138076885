import { updateFormPayment } from 'store/actions/settings';

import { default as LiqpayConfigurator } from "./LiqpayConfigurator";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  data: state.settings.paymentEditForm,
  restaurants: state.settings.restaurants,
  kitchens: state.settings.kitchens,
});

const mapDispatchToProps = { updateFormPayment };

export default connect(mapStateToProps, mapDispatchToProps)(LiqpayConfigurator);
