export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA";
export const UPDATE_ORDER_CLIENT = "UPDATE_ORDER_CLIENT";
export const UPDATE_ORDER_CLIENT_GET = "UPDATE_ORDER_CLIENT_GET";
export const UPDATE_ORDER_ADDRESS = "UPDATE_ORDER_ADDRESS";
export const RESET_ORDER_ADDRESS = "RESET_ORDER_ADDRESS";
export const UPDATE_ORDER_ITEMS = "UPDATE_ORDER_ITEMS";
export const ADD_ORDER_ITEM = "ADD_ORDER_ITEM";
export const REMOVE_ORDER_ITEM = "REMOVE_ORDER_ITEM";
export const SELECT_PIZZA_HALF = "SELECT_PIZZA_HALF";
export const SELECT_SWAPPABLE_PRODUCT = "SELECT_SWAPPABLE_PRODUCT";
export const INCREASE_ORDER_ITEM_QUANTITY = "INCREASE_ORDER_ITEM_QUANTITY";
export const DECREASE_ORDER_ITEM_QUANTITY = "DECREASE_ORDER_ITEM_QUANTITY";
export const UPDATE_ORDER_ITEM_COMMENT = "UPDATE_ORDER_ITEM_COMMENT";
export const UPDATE_ORDER_PAYMENTS = "UPDATE_ORDER_PAYMENTS";
export const ADD_ORDER_PAYMENTS = "ADD_ORDER_PAYMENTS";
export const REMOVE_ORDER_PAYMENTS = "REMOVE_ORDER_PAYMENTS";
export const RESET_ORDER = "RESET_ORDER";
export const GET_KITCHEN_ADDRESS = "GET_KITCHEN_ADDRESS";
export const GET_ACTIVE_KITCHENS_CITY = "GET_ACTIVE_KITCHENS_CITY";
export const GET_KITCHEN_BY_ADDRESS = "GET_KITCHEN_BY_ADDRESS";
export const GET_ITEM_ORDER_BY_ID = "GET_ITEM_ORDER_BY_ID";
export const UPDATE_ITEM_DOP_ADD = "UPDATE_ITEM_DOP_ADD";
export const UPDATE_DELETE_DOP = "UPDATE_DELETE_DOP";
export const UPDATE_ITEM_DELETE = "UPDATE_ITEM_DELETE";
export const GET_LIST_STREET = "GET_LIST_STREET";
export const CLEANING_LIST_STREET = "CLEANING_LIST_STREET";
export const ADD_ONE_DOP = "ADD_ONE_DOP";
export const SUBTRACT_ONE_DOP = "SUBTRACT_ONE_DOP";
export const UPDATE_LOAD_CLIENT = "UPDATE_LOAD_CLIENT";
export const LOAD_CLIENT = "LOAD_CLIENT";
export const ORDER_UPDATE_FORM_REASON = "ORDER_UPDATE_FORM_REASON";
export const ORDER_UPDATE_FORM_REASON_STATUS = "ORDER_UPDATE_FORM_REASON_STATUS";
export const SET_ORDER_ITEMS = "SET_ORDER_ITEMS";
export const REMOVE_PROMO_GIFT = "REMOVE_PROMO_GIFT";
export const GET_ORDER_PRINT = "GET_ORDER_PRINT";
export const CLEAТ_FORM_CLIENT = "CLEAТ_FORM_CLIENT";
export const GET_ORDERS_ALL_CLIETN_ID = "GET_ORDERS_ALL_CLIETN_ID";
export const CLEAN_FORM_REASON = "CLEAN_FORM_REASON";
export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const REMOVE_PROMO_GIFT_DISCOUNT = "REMOVE_PROMO_GIFT_DISCOUNT";
export const REMOVE_ORDER_PROMOCODE = "REMOVE_ORDER_PROMOCODE";
export const CLEAR_DATA_CLIENT_ORDER = "CLEAR_DATA_CLIENT_ORDER";
export const ADD_CUTLERY_ITEM = "ADD_CUTLERY_ITEM";
export const UPDATE_CUTLERY_ITEM = "UPDATE_CUTLERY_ITEM";
export const REMOVE_CUTLERY_ALL = "REMOVE_CUTLERY_ALL";
export const REMOVE_CUTLERY_ITEM = "REMOVE_CUTLERY_ITEM";
export const GET_UPDATE_ORDER = "GET_UPDATE_ORDER";
export const UPDATE_ORDER_PAYMENTS_ITEM = "UPDATE_ORDER_PAYMENTS_ITEM";
export const REASON_GET_INFORMATION = "REASON_GET_INFORMATION";
export const DELETE_BELONS_PRODUCT = "DELETE_BELONS_PRODUCT";
export const UPDATE_ORDER_ITEM_QUANTITY = "UPDATE_ORDER_ITEM_QUANTITY";
export const CLEAN_FORM_ORDER = "CLEAN_FORM_ORDER";
export const SET_ORDER_DELIVERY_TIME = "SET_ORDER_DELIVERY_TIME";
export const SET_ORDER_DELIVERY_TIME_LOADING = "SET_ORDER_DELIVERY_TIME_LOADING";