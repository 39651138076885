import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as ordersActions from "store/actions/orders";

import { default as ModalListOfCouriers } from "./ModalListOfCouriers";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalListCouriers.isOpen,
        loadingsModal: state.view.modalListCouriers.loadingsModal,
        courierUpdatePending: state.loadings.loadModalEdition.create,
        couriersList: state.couriers.list,
        order: state.order.data,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...orderActions,
    ...ordersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListOfCouriers);
