// Login icon for Users table callback button
import { Login, Visibility } from "@mui/icons-material";

export const tableSales = [
  {
    title: "Фіксальний №",
    width: "",
    key: "check_id",
  },
  {
    title: "Касир",
    width: "",
    key: "cashier_name",
  },
  {
    title: "Кухня",
    width: "",
    key: "kitchen",
  },
  {
    title: "Знижка",
    width: "",
    key: "discount",
  },
  {
    title: "Тип оплати",
    width: "",
    key: "payments",
  },
  {
    title: "ПРРО",
    width: "",
    key: "numfiscal",

  },
  {
    title: "Сума грн.",
    width: "",
    key: "sum",
  },
  {
    title: "Дата",
    width: "",
    key: "date",
  },
  // {
  //   title: "",
  //   width: "",
  // },
];

export const tableAnswer = [
  {
    title: "№",
    width: "",
    type: "text",
    key: "order_id",
  },
  {
    title: "Клієнт",
    width: "",
    type: "textClick",
    key: "client_name",
    fun: "client",
  },
  {
    title: "Телефон",
    width: "",
    type: "phone",
    key: "client_phone",
  },
  {
    title: "Р.",
    width: "",
    type: "text",
    key: "rating_site",
  },
  {
    title: "ком. Сайт",
    width: "",
    type: "text",
    key: "comment_site",
    width: "150px",
  },
  {
    title: "Р.",
    width: "",
    type: "text",
    key: "rating_operator",
  },
  {
    title: "ком. Оператор",
    width: "",
    type: "text",
    key: "comment_operator",
    width: "150px",
  },
  {
    title: "Р.",
    width: "",
    type: "text",
    key: "rating_courier",
  },
  {
    title: "ком. Кур'єр",
    width: "150px",
    type: "text",
    key: "comment_courier",
  },
  {
    title: "Р.",
    width: "",
    type: "text",
    key: "rating_kitchen",
  },
  {
    title: "ком. Кухня",
    width: "",
    type: "text",
    key: "comment_kitchen",
    width: "150px",
  },
  {
    title: "Кухня",
    width: "",
    type: "text",
    key: "kitchen",
  },
  {
    title: "Оператор",
    width: "",
    type: "text",
    key: "operator_full_name",
  },
  {
    title: "Кур'єр",
    width: "",
    type: "text",
    key: "courier_full_name",
  },
  {
    title: "Створений",
    width: "",
    type: "text",
    key: "created_at",
  },
  {
    title: "Доставлено",
    width: "",
    type: "text",
    key: "delivered_at",
  },
];

export const tableCallCenter = [
  {
    title: "Час",
    width: "",
  },
  {
    title: "Оператор",
    width: "",
  },
  {
    title: "рес",
    width: "",
  },
  {
    title: "Номер",
    width: "",
  },
  {
    title: "Кухня",
    width: "",
  },
  {
    title: "Місто",
    width: "",
  },
  {
    title: "Адреса",
    width: "",
  },
  {
    title: "Клієнт",
    width: "",
  },
  {
    title: "Телефон",
    width: "",
  },
];

export const tableUsers = [
  {
    title: "Ім'я",
    width: "",
    key: "first_name",
  },
  {
    title: "Призвіще",
    width: "",
    key: "last_name",
  },
  {
    title: "Телефон",
    width: "",
    key: "phone",
    type: "phone"
  },
  {
    title: "SIP ID",
    width: "",
    key: "sip_id",
  },
  {
    title: "Місто",
    width: "",
    key: "city_name",
  },
  {
    title: "Кухня",
    width: "",
    key: "kitchens",
    type: "array",
    keyArray: "title",
  },
  {
    title: "Посада",
    width: "",
    key: "role_title",
  },
  // {
  //   title: "iiko ID",
  //   width: "",
  //   key: "iiko",
  //   type: "obj",
  //   item: "iiko_id"
  // },
  {
    title: 'Статус',
    width: "",
    key: 'status',
  },
  {
    title: "",
    key: "callback",
    actionKey: "generateAuthLink",
    width: "40px",
    icon: Login,
  },
];

export const statisticKitchen = [
  {
    title: "Ім'я",
    width: "",
    key: "name",
  },
  {
    title: "Кухня",
    width: "",
    key: "kitchen_title",
  },
  {
    title: "Замовлення у місті",
    width: "",
    key: "orders_within_city",
  },
  {
    title: "Замовлення за місто",
    width: "",
    key: "orders_outside_city",
  },
  {
    title: "Середня дистанція, км",
    width: "",
    key: "medium_distance",
  },
  {
    title: "Дистанція, км",
    width: "",
    key: "distance",
  },
];

export const deliveryRoute = [
  {
    title: "ID",
    width: "",
    key: "id",
  },
  {
    title: "Час",
    width: "",
    key: "timestamp",
  },
  {
    title: "Широта",
    width: "",
    key: "latitude",
  },
  {
    title: "Довгота",
    width: "",
    key: "longitude",
  },
];

export const statsDeliveries = [
  {
    title: "Поїздка",
    width: "",
    key: "id",
  },
  {
    title: "Кухня",
    width: "",
    key: "kitchen",
  },
  {
    title: "Замовлення",
    width: "",
    key: "delivery_orders",
    type: "list",
    items: ["order_id", "address", "restaurant"],
    getStyle: (item) => (item.status === "rejected" ? {
      background: "gray",
      width: "max-content",
      borderRadius: "5px",
    } : {}),
  },
  {
    title: "Закрито",
    width: "",
    key: "delivered",
  },
  {
    title: "Дистанція, км",
    width: "",
    key: "distance",
  },
];

export const tableClietns = [
  {
    title: "Ім'я",
    width: "",
    key: "name",
  },
  {
    title: "Телефон",
    width: "",
    key: "phone",
    type: "phone"
  },
  {
    title: "Дата народження",
    width: "",
    key: "birthday",
  },
  {
    title: "В чорному списку",
    width: "",
    key: "blocking_reasons",
  },
  {
    title: "Ресурс",
    width: "",
    key: "source",
    type: "obj",
    item: "name"
  },
];

export const tableMagazineComplaints = [
  {
    title: "Id",
    key: "id",
    width: "",
  },
  {
    title: "Ім'я",
    key: "operator_name",
    width: "",
  },
  {
    title: "Ресторан",
    key: "restaurant",
    width: "",
  },
  {
    title: "Тип",
    key: "type",
    width: "",
  },
  {
    title: "Значення",
    key: "value",
    width: "",
  },
  {
    title: "Час",
    key: "date",
    width: "",
  },
];

export const tableClientOrders = [
  {
    title: "Id",
    width: "",
  },
  {
    title: "Телефон",
    width: "",
  },
  {
    title: "Адрес",
    width: "",
  },
  {
    title: "Кухня",
    width: "",
  },
  {
    title: "Час",
    width: "",
  },
];

export const tableKitchen = [
  {
    title: "Назва",
    key: 'title',
    width: "",
  },
  {
    title: "Місто",
    key: 'city_name',
    width: "",
  },
  {
    title: "Адреса",
    key: 'address',
    width: "",
  },
  {
    title: "Відчиняється",
    key: 'time_open',
    width: "",
  },
  {
    title: "Заченняється",
    key: 'time_close',
    width: "",
  },
  {
    title: "Працює/не працює",
    key: 'status',
    width: "",
  },
];


export const tableRestaurants = [
  {
    title: "Емблема",
    key: 'logo_path',
    width: "",
    type: "img",
  },
  {
    title: "Код",
    key: 'code',
    width: "",
  },
  {
    title: "Назва",
    key: 'name',
    width: "",
  },
  {
    title: "Статус",
    key: 'status',
    width: "",
  },
];

export const tableCities = [
  {
    title: "id",
    key: 'id',
    width: "",
  },
  {
    title: "Назва",
    key: 'name_ua',
    width: "",
  },
  {
    title: "Широта",
    key: 'latitude',
    width: "",
  },
  {
    title: "Довгота",
    key: 'longitude',
    width: "",
  },
  {
    title: "Uuid",
    key: 'uuid',
    width: "",
  },
  {
    title: "Статус",
    key: 'status',
    width: "",
  },
];

export const tableLocations = [
  {
    title: "id",
    key: 'id',
    width: "",
  },
  {
    title: "Назва",
    key: 'name',
    width: "",
  },
  {
    title: "Місто",
    key: 'city',
    width: "",
  },
  {
    title: "Вулиця",
    key: 'street_ua',
    width: "",
  },
  {
    title: "Будинок",
    key: 'house_number',
    width: "",
  },
  {
    title: "Ресторан",
    key: 'restaurant',
    width: "",
  },
  {
    title: "Кухня",
    key: 'kitchen_code',
    width: "",
  },
];

export const tableOrdersKitchen = [
  {
    title: "Kухня",
    key: 'kitchen',
    width: "",
  },
  {
    title: "Нові",
    key: 'new',
    width: "",
  },
  {
    title: "Готоються",
    key: 'cooking',
    width: "",
  },
  {
    title: "Пакуються",
    key: 'preparing',
    width: "",
  },
  {
    title: "Доставляються",
    key: 'delivered',
    width: "",
  },
];

export const tableRoles = [
  {
    title: "id",
    key: "id",
    width: "",
  },
  {
    title: "Назва",
    key: "title",
    width: "",
  },
  {
    title: "Код",
    key: "name",
    width: "",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tablePromotions = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "Назва",
    key: "name",
    width: "",
  },
  {
    title: "Місто",
    key: "city",
    width: "",
  },
  {
    title: "Статус",
    key: "status",
    width: "",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tablePromocodes = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "Назва",
    key: "title",
    width: "",
  },
  {
    title: "Бренд",
    key: "restaurant",
    width: "",
  },
  {
    title: "Статус",
    key: "status",
    width: "",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
]

export const tableresource = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "Назва",
    key: "name",
    width: "",
  },
  {
    title: "Статус",
    key: 'status',
    width: "",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tablePayment = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "Назва",
    key: "title",
    width: "",
  },
  {
    title: "Тип оплати",
    key: "payment_type_label",
    width: "",
  },
  {
    title: "Безкоштовно",
    key: "free_label",
    width: "",
  },
  {
    title: "Працює як знижка",
    key: 'is_discount',
    width: "",
  },
  {
    title: "Статус",
    key: 'status',
    width: "",
  },
];

export const statisticSelf = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "ПІБ",
    key: "name",
    width: "",
  },
  {
    title: "Посада",
    key: "position",
    width: "",
  },
  {
    title: "заг. кі-сть замовлень",
    key: "orders",
    width: "",
  },
  {
    title: "загальна сума замовлень",
    key: "sum",
    width: "",
  },
];

export const statisticComplaints = [
  {
    title: "id",
    key: "id",
    width: "40px",
  },
  {
    title: "Клієнт",
    key: "client_full_name",
    width: "",
  },
  {
    title: "Номер кліє.",
    key: "client_phone",
    width: "",
    type: "phone"
  },
  {
    title: "Комент.",
    key: "comment",
    width: "",
  },
  {
    title: "Кухня",
    key: "kitchen",
    width: "",
  },
  {
    title: "Оператор",
    key: "operator_name",
    width: "",
  },
  {
    title: "Рестора",
    key: "restaurant_name",
    width: "",
  },
  {
    title: "Ресурс",
    key: "source",
    width: "",
  },
  {
    title: "Тип скарги",
    key: "type",
    width: "",
  },
  {
    title: "Статус обробки",
    key: "processed",
    width: "",
  },
  {
    title: "Час замовлення",
    key: "order_date",
    width: "",
  },
];

export const tableProducts = [
  {
    title: "Фото",
    key: 'image',
    type: "img",
    width: "50px",
  },
  {
    title: "id",
    key: "id",
  },
  {
    title: "Артикул",
    key: "article",
  },
  {
    title: "Назва",
    key: "title_ua",
  },
  {
    title: "Категорія",
    key: "category",
    type: "obj",
    item: "name"
  },
  {
    title: "Тип ваги",
    key: "weight_type",
  },
  {
    title: "Специфікація",
    key: "callback",
    actionKey: "getProductSpecification",
    width: "40px",
    icon: Visibility,
  },
  {
    title: "Ресторан",
    key: "restaurant",
  }
];

export const tableStreets = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Назва",
    key: "street",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tableTypeOfDelivery = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Назва",
    key: "name",
  },
  {
    title: "Типи оплат",
    width: "",
    key: "payments",
    type: "array",
    keyArray: "title",
  },
  {
    title: "Код",
    key: "code",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tableComplaint = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Назва",
    key: "name",
  },
  {
    title: "Статус",
    key: 'status',
    width: "",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tableBugReport = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Критичність",
    key: "severity",
    width: "50px",
  },
  {
    title: "Сторінки",
    key: "pages",
    width: "100px",
    type: "array",
    keyArray: "label",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];

export const tableCategories = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Назва",
    key: "name",
  },
  {
    title: "Ресторан",
    key: 'restaurant',
    width: "",
  },
  {
    title: 'Статус',
    width: "",
    key: 'sort',
  },
  {
    title: 'Статус',
    width: "",
    key: 'status',
  }
];

export const tableReasons = [
  {
    title: "id",
    key: "id",
    width: "50px",
  },
  {
    title: "Назва",
    key: "title",
  },
  {
    title: "",
    key: "delete",
    width: "40px",
  },
];