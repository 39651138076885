import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";
import { isArray, isString } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Input from "components/ui-kit/Input";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import SvgIcon from "components/ui-kit/SvgIcon";
import Checkbox from "components/ui-kit/Checkbox";
import Base from "../Base";
import { LoadingModal } from "../../loadingOrder/loading";
import ModalDelete from "../ModalDelete";
import styles from "./Users.module.scss";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { citiesMapped } from "utils/mappers/cities";
import { parsePhone } from "utils/helpers/parsers";
import { validateUser } from "utils/validation";
import { searchPage, deleteMatchingPagesStatistic } from "utils/helpers/pages";
import { isMyKitchen, isMyUsersRole } from "utils/helpers/checks";
import {
  kitchensMappedSelectId,
  kitchenSearchSelectId,
  kitchenSearchSelectFilterId
} from "utils/mappers/kitchens";
import {
  rolesMappedSelectId,
  rolesArrayId,
  rolesSearchId,
  rolesMappedSelect,
  rolesSearchName,
  rolesSearchIdName,
  roleSearchOnIdName
} from "utils/mappers/roles";

import {
  RADIO_SCHEME_USER,
  RADIO_SCHEME_USER_STATISTIC,
  RADIO_SCHEME_USER_IN_THE_KITCHENS,
  RADIO_SCHEME_AVAILABLE_USERS
} from "constants/schemes";

import { selectPagesStatistic } from "constants/selectPages";
import { transportMapped } from "utils/mappers/transports";
import { removeDublicatesKitchenId } from "utils/helpers/removeDublicates";

function Users({
  // props "state"
  isOpen,
  editedUser,
  cities,
  roles,
  productTypes,
  isOpenModalDelete,
  isLoadModalDelete,
  isLoadModalUser,
  locationsState,
  kitchens,
  user,
  transport,
  // redux
  closeAllModals,
  clearEditedUser,
  createUser,
  editUser,
  updateModalDelete,
  deleteUser,
  updateFormUser,
  updateFormUserLikoId,
  resetTheAppCourier,
  filMy = () => { },
  getAllTransport = () => { },
}) {
  const theme = useTheme();
  const location = useLocation();

  const isMyKit = isMyKitchen(user);
  const isMyUser = isMyUsersRole(user);

  const {
    id = null,
    first_name,
    last_name,
    phone,
    sip_id,
    status,
    iiko,
    locations,
    role_name,
    role_title,
    product_types,
    kitchens_statistic,
    kitchens_statistic_status,
    kitchens_sales_status,
    kitchens_sales,
    users_by_kitchen,
    available_users,
    city_sync_id,
    statistics_pages,
    cart_notify_message,
    follow_order_message,
    transport_type
  } = editedUser;

  const isCourier = role_name === "courier";

  const [locationsData, setLocationsDate] = useState({ locations: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [stateLoadBtn, setStateLoadBtn] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    iiko: "",
    locations: "",
    role_name: "",
  });

  useEffect(() => {
    if (city_sync_id)
      prepareLocatios();
  }, [city_sync_id]);

  useEffect(() => {
    if (isMyUser) {
      handleUserChange({ valueKey: "role_name", value: roleSearchOnIdName(user?.available_users_roles?.[0] || 1, roles) });
    }

    if (isMyKit) {
      handleUserChange({ valueKey: "kitchens", value: [kitchenSearchSelectId(user.kitchens, kitchens)[0]] });
    }
  }, [user]);

  // поиск доп мест кухни
  const prepareLocatios = () => {
    let output = [];

    locationsState.map((el) => {
      if (el.city_sync_id == city_sync_id)
        output.push({ ...el, value: el.kitchen_code, label: el.name });
    });

    setLocationsDate((prev) => (
      {
        ...prev,
        ...{ locations: output }
      }
    ))
  };

  const selectedLocation = (locations) => {
    let output = [];

    locations.map((el) => {
      output.push({ ...el, value: el.kitchen_code, label: el.name });
    });

    return output;
  }

  const handleCloseModal = () => {
    closeAllModals();
    clearEditedUser();
  };

  const handleUserChange = ({ valueKey, value }) => {
    switch (valueKey) {
      case "allTypeProduct":
        if (!isArray(value))
          updateFormUser('product_types', []);

        updateFormUser('product_types', value.map(el => el.value));
        break;
      case "productType":
        updateFormUser('product_types', value.map((el) => el.value));
        break;
      case "iiko":
        if (id) {
          updateFormUserLikoId(value);
        } else {
          updateFormUser(valueKey, value);
        }
        break;
      case "locations":
      case "first_name":
      case "last_name":
      case "iiko":
      case "phone":
      case "sip_id":
      case "password":
      case "city_sync_id":
      case "statistics_pages":
      case "transport_type":
      case "cart_notify_message":
      case "follow_order_message":
        updateFormUser(valueKey, value);
        break;
      case "status":
        updateFormUser(valueKey, value.value);
        break;
      case "kitchens_statistic":
      case "kitchens":
      case "kitchens_sales":
        updateFormUser(valueKey, kitchenSearchSelectFilterId(value));
        break;
      case "available_users_roles":
        updateFormUser(valueKey, rolesArrayId(value));
        break;
      default:
        updateFormUser(valueKey, value.value);
        break;
    }
  };

  const handleSubmit = () => {
    const parsedPhone = parsePhone(`${phone}`);
    const isEdit = editedUser.hasOwnProperty("id");
    const action = isEdit ? editUser : createUser;

    validateUser({
      data: {
        first_name,
        last_name,
        phone: parsedPhone,
        sip_id,
        role_name,
        status: status || "active",
        kitchens_statistic,
        kitchens: removeDublicatesKitchenId(editedUser.kitchens),
        kitchens_statistic_status,
        users_by_kitchen,
        available_users,
        city_sync_id,
        statistics_pages,
        transport_type
      },

      onSuccess: async (validData) => {
        setIsLoading(true);

        let datas = {
          ...validData,
          kitchens_statistic,
          kitchens: removeDublicatesKitchenId(editedUser.kitchens),
          kitchens_statistic_status,
          users_by_kitchen,
          available_users,
          city_sync_id,
          statistics_pages,
          transport_type,
          kitchens_sales_status,
          kitchens_sales,
          cart_notify_message,
          follow_order_message,
          iiko_id: id ? editedUser?.iiko?.iiko_id : editedUser?.iiko,
          ...(editedUser.password && { password: editedUser.password }),
          ...(editedUser.product_types && {
            product_types: editedUser.product_types,
          }),
        }

        if (editedUser?.available_users_roles && editedUser?.available_users_roles.length > 0) {
          datas.available_users_roles = editedUser?.available_users_roles;
        } else {
          datas.available_users_roles = [];
          datas.available_users_roles.push(1);
        }

        await action({
          user: {
            ...datas,
          },

          onSuccess: () => {
            handleCloseModal();
            setIsLoading(false);
          },
          onError: (inputsErrors) => {
            setErrors(inputsErrors);
            setIsLoading(false);
          },
          id: editedUser.id,
          filMy
        });
      },
      onError: (validationErrors) => {
        setErrors(validationErrors);
      },
    });
  };

  const handleDeleteOpenModal = () => {
    updateModalDelete('isOpen', true);
  }

  const handleResetTheApp = () => {
    resetTheAppCourier(setStateLoadBtn);
  }

  const activeProduct = () => {
    let output = [];

    if (isArray(product_types)) {
      if (product_types.length > 0)
        productTypes.map(itemTypes => {
          product_types.map((active) => {
            if (itemTypes.value == active) {
              output.push(itemTypes);
            }
          })
        })
    }

    return output;
  }

  const renderProductTypeField = () => {
    if (role_name !== "cook") {
      return null;
    }

    return (
      <>
        <Select
          selectOptions={productTypes}
          selectedOption={activeProduct() || []}
          valueKey='productType'
          label='Тип Кухаря'
          error={errors.role_name}
          isMulti
          onChange={handleUserChange}
        />
        <div className={styles.row_add_delete}>
          <button className={styles.btn_text} onClick={() => handleUserChange({ valueKey: "allTypeProduct", value: productTypes })}>Додати всі</button>
        </div>
      </>
    );
  };

  useEffect(() => {
    getAllTransport();
  }, []);

  return (
    <Wrapper theme={theme} isOpen={isOpen}>
      <Base
        onClose={handleCloseModal}
        configuration={{
          name: "productCreate",
          fields: [
            {
              type: "customField",
              renderCustomField: () => (
                <div className={styles.header}>
                  <p className={`${styles.header__name} font-600`}>
                    {
                      id ? `${first_name || ""} ${last_name || ""}` : 'Створити нового'
                    }
                  </p>
                  <SvgIcon
                    type='close'
                    className={styles.header__close}
                    onClick={handleCloseModal}
                  />
                </div>
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Input
                  label="Ім'я"
                  value={first_name}
                  valueKey='first_name'
                  error={errors.first_name}
                  mode='secondary'
                  placeholder="Введите Ім'я"
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Input
                  label='Призвіще'
                  value={last_name}
                  valueKey='last_name'
                  error={errors.last_name}
                  mode='secondary'
                  placeholder='Введите Призвіще'
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <WRInputPhone>
                  <InputPhone
                    title='Номер телефону'
                    type='text'
                    value={phone}
                    valueKey='phone'
                    width="100%"
                    onChange={(value) => handleUserChange({ valueKey: 'phone', value })}
                  />
                </WRInputPhone>
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Input
                  label="SIP ID"
                  value={sip_id}
                  valueKey='sip_id'
                  error={errors.sip_id}
                  mode='secondary'
                  placeholder="Введіть SIP ID"
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Input
                  label='Пароль'
                  value={editedUser?.password || ""}
                  valueKey='password'
                  error={errors.password}
                  mode='secondary'
                  type='password'
                  placeholder='Введите пароль'
                  onChange={handleUserChange}
                />
              ),
            },
            // {
            //   type: "customField",
            //   renderCustomField: () => (
            //     <Input
            //       label='IIKO ID'
            //       value={id ? (iiko?.iiko_id || '') : (iiko || '')}
            //       valueKey='iiko'
            //       error={errors.iiko}
            //       mode='secondary'
            //       placeholder='Введите IIKO ID'
            //       onChange={handleUserChange}
            //     />
            //   ),
            // },
            {
              type: "customField",
              renderCustomField: () => (
                <Dropdown
                  title="Місто"
                  onChange={(e) => handleUserChange({ valueKey: 'city_sync_id', value: e.target.value })}
                  value={city_sync_id}
                  list={isMyKit ? citiesMapped(cities, user.city_sync_id) : citiesMapped(cities)}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <>
                  <br />
                  <Select
                    selectOptions={isMyUser ? rolesSearchIdName(user.available_users_roles, roles) : rolesMappedSelect(roles)}
                    selectedOption={rolesSearchName(role_name, roles)}
                    valueKey='role_name'
                    label='Посада'
                    error={errors.role_name}
                    onChange={handleUserChange}
                  />
                </>
              ),
            },
            {
              type: "customField",
              renderCustomField: () => renderProductTypeField(),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Select
                  selectOptions={isMyKit ? kitchenSearchSelectId(user.kitchens, kitchens) : kitchensMappedSelectId(kitchens, city_sync_id)}
                  selectedOption={kitchenSearchSelectId(editedUser.kitchens, kitchens)}
                  valueKey='kitchens'
                  label='Кухні'
                  isMulti
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                id && (
                  <Select
                    selectOptions={locationsData.locations}
                    selectedOption={selectedLocation(locations) || []}
                    valueKey='locations'
                    label='Локації'
                    error={errors.locations}
                    isMulti
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                isCourier && (
                  <>
                    <Dropdown
                      title="Транспорт"
                      onChange={(e) => handleUserChange({ valueKey: 'transport_type', value: e.target.value })}
                      value={transport_type || ''}
                      list={transportMapped([...[{ title: "-", value: '' }], ...transport.list])}
                    />
                    <br />
                  </>
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Radio
                  colors={"#ffffff"}
                  items={RADIO_SCHEME_USER}
                  label='Статус'
                  valueKey='status'
                  value={status}
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                !isCourier && (
                  <Radio
                    colors={"#ffffff"}
                    items={RADIO_SCHEME_USER_STATISTIC}
                    label='Доступні локації'
                    valueKey='kitchens_statistic_status'
                    value={kitchens_statistic_status}
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                (kitchens_statistic_status === "certain") && (
                  <Select
                    selectOptions={kitchensMappedSelectId(kitchens, city_sync_id)}
                    selectedOption={kitchenSearchSelectId(kitchens_statistic, kitchens)}
                    valueKey='kitchens_statistic'
                    label='Кухні'
                    isMulti
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                searchPage({ pages: editedUser.pages, page: "statistic" }) && (
                  <Select
                    selectOptions={deleteMatchingPagesStatistic({ pagesAll: selectPagesStatistic, pages: editedUser.pages, typePage: "statistic" })}
                    selectedOption={statistics_pages ?? []}
                    valueKey='statistics_pages'
                    label='Додаткове налаштування для доступу до сторінок статистики. Наприклад, дати доступ до сторінки, окремо користувачу. Якщо у ролях додані всі сторінки, то у полі нище буде пусто.'
                    isMulti
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                !isCourier && (
                  <Radio
                    colors={"#ffffff"}
                    items={RADIO_SCHEME_USER_IN_THE_KITCHENS}
                    label='Співробітники по кухнях'
                    valueKey='users_by_kitchen'
                    value={users_by_kitchen}
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                !isCourier && (
                  <Radio
                    colors={"#ffffff"}
                    items={RADIO_SCHEME_AVAILABLE_USERS}
                    label='Доступні співробітники'
                    valueKey='available_users'
                    value={available_users}
                    onChange={handleUserChange}
                  />
                )
              ),
            },

            {
              type: "customField",
              renderCustomField: () => (
                (available_users === "my") && (
                  <Select
                    selectOptions={rolesMappedSelectId(roles)}
                    selectedOption={rolesSearchId(editedUser?.available_users_roles || [], roles)}
                    valueKey='available_users_roles'
                    label='Ролі'
                    isMulti
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            // --- kitchens_sales_status
            {
              type: "customField",
              renderCustomField: () => (
                <Radio
                  colors={"#ffffff"}
                  items={RADIO_SCHEME_USER_STATISTIC}
                  label='Доступність кухні "Звіт по чеку"'
                  valueKey='kitchens_sales_status'
                  value={kitchens_sales_status}
                  onChange={handleUserChange}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Checkbox 
                  isMin={true} 
                  title='Надсилати сповіщення за забутий кошик' 
                  checked={!!cart_notify_message} 
                  value={!!cart_notify_message}
                  onChecked={(previous) => handleUserChange({ 
                    valueKey: 'cart_notify_message', 
                    value: +(!previous),
                  })}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                <Checkbox 
                  isMin={true} 
                  title='Надсилати повідомлення про відстеження замовлення' 
                  checked={!!follow_order_message} 
                  value={!!follow_order_message}
                  onChecked={(previous) => handleUserChange({ 
                    valueKey: 'follow_order_message', 
                    value: +(!previous),
                  })}
                />
              ),
            },
            {
              type: "customField",
              renderCustomField: () => (
                (kitchens_sales_status === "certain") && (
                  <Select
                    selectOptions={kitchensMappedSelectId(kitchens, city_sync_id)}
                    selectedOption={kitchenSearchSelectId(kitchens_sales, kitchens)}
                    valueKey='kitchens_sales'
                    label='Кухні'
                    isMulti
                    onChange={handleUserChange}
                  />
                )
              ),
            },
            // --- end  kitchens_sales_status
            {
              type: "customField",
              renderCustomField: () => (
                <div className={styles.footer}>
                  {
                    isCourier && isString(iiko?.iiko_id) && (iiko?.iiko_id.length > 0) && (
                      <div className={styles.footer_row_top}>
                        <ButtonIcon
                          title='Скинути додаток'
                          onClick={handleResetTheApp}
                          colorBg="red"
                          icon={<CancelIcon />}
                          width={"100%"}
                          positionIcon="left"
                          isLoad={stateLoadBtn}
                        />
                      </div>
                    )
                  }
                  <div className={styles.footer_row}>
                    <Item>
                      <ButtonIcon
                        title='Зберегти'
                        colorBg="green"
                        icon={<SaveIcon />}
                        onClick={handleSubmit}
                        width={"100%"}
                      />
                    </Item>
                  </div>
                </div>
              ),
            },
          ],
        }}
      />
      
      <ModalDelete
        title={'Видалити'}
        text={`Ви підтверджуєте видалення "${role_title}" - ${first_name} ${last_name} ?`}
        isOpen={isOpenModalDelete}
        isLoad={isLoadModalDelete}
        funDelete={() => deleteUser(location?.query?.page, filMy)}
        updateModalDelete={updateModalDelete}
      />

      {
        (isLoadModalUser || isLoading) && (
          <LoadingModal />
        )
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 100;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  background-color: ${(p) => p.theme.background};
`;

const Item = styled.div`
  margin: 0 20px 15px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const WRInputPhone = styled.div`
  margin-bottom: 15px;
`;
Users.propTypes = {
  closeAllModals: PropTypes.func.isRequired,
  clearEditedUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editedUser: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  editUser: PropTypes.func.isRequired,
  productTypes: PropTypes.array.isRequired,
};

export default Users;
