import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import moment from "moment";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TimeInput } from "components/ui-kit/inputs/TimeInput";
import Radio from "components/ui-kit/Radio";
import { LoadingModal } from "components/loadingOrder/loading";
import InputPhone from "components/ui-kit/Modals/InputPhone";

import SaveIcon from "@mui/icons-material/Save";

import styles from "./../../scss/settings.module.scss";
import classes from "./Kitchen.module.scss";
import classnames from "classnames";

import { citiesMapped } from "utils/mappers/cities";
import { validateKitchen } from "utils/validation";
import { hour, getMinute, getHour, minute } from "utils/helpers/time";
import { Key as KeyIcon } from "@mui/icons-material";
import { CleaningServices as CleanUpIcon } from "@mui/icons-material";

import { hours, minutes } from "constants/watch";
import { RADIO_SCHEME, RADIO_SCHEME_PICKUP, RADIO_SCHEME_CHANGES } from "constants/schemes";

const Kitchen = ({
    // state
    data,
    cities,
    restaurants,
    isLoadPage,
    //dispatch
    updateKitchenForm,
    updateKitchenFormShiftFirst,
    updateKitchenFormShiftLast,
    createKitchen,
    updateKitchen,
    getKitchen,
    getKitchenShiftStatus,
    executeKitchenShiftCashalotCommand,
    cleaningNewForm
}) => {
    const {
        kitchenShiftLoading, // Чи завантажується закриття/відкриття зміни (запит до веб-серверу)
        kitchenShiftStatus,
    } = useSelector(state => state.kitchens);

    const [restaurant, setRestaurant] = useState(restaurants[0]?.code);
    const [error, setErrors] = useState({
        title: "",
        address: "",
        city_sync_id: "",
        latitude: "",
        longitude: "",
        time_open: "",
        time_close: "",
        create_cashless_checks_to: "",
        create_cash_checks_to: ""
    });
    const [changes, setChanges] = useState(RADIO_SCHEME_CHANGES[0].value);
    const { shifts } = data;
    const shiftFirst = shifts[0];
    const shiftLast = shifts[1];

    let { code } = useParams();
    let history = useHistory();

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    useEffect(() => {
        if (code !== "new") {
            getKitchen(code);
            executeKitchenShiftCashalotCommand(code, "GetShift");
        }

        if (code == "new")
            cleaningNewForm('editKitchens');

        return getKitchenShiftStatus; // get original kitchen shift status
    }, []);

    const createKitchenCallback = (code) => {
        history.push(`/settings/general/kitchens`);
    }

    const handleSubmit = () => {
        validateKitchen({
            data: {
                title: data.title,
                address: data.address,
                city_sync_id: data.city_sync_id,
                latitude: data.latitude,
                longitude: data.longitude,
                time_open: data.time_open,
                time_close: data.time_close,
                create_cashless_checks_to: data.create_cashless_checks_to,
                create_cash_checks_to: data.create_cash_checks_to
            },

            onSuccess: async (validData) => {
                if (data?.id) {
                    updateKitchen();
                } else {
                    createKitchen(createKitchenCallback);
                }

                setErrors({
                    title: "",
                    address: "",
                    city_sync_id: "",
                    latitude: "",
                    longitude: "",
                    time_open: "",
                    time_close: "",
                    create_cashless_checks_to: "",
                    create_cash_checks_to: ""
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={`${styles.d_max}`}>
            <Back />

            <div className={`${styles.title_kitchen}`}>Кухня: <span>{data.title}</span></div>

            <div className={`${styles.d_grid}  ${styles.col_2} ${styles.block}`}>
                <div className={styles.left}>
                    <div className={styles.form}>
                        <div className={`${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Загальне </div>
                            <div className="wr-block-content">
                                <div className={`${styles.row_form} ${styles.row_form_flex_end}`}>
                                    <div className={`${styles.item_two}`}>
                                        <TextInput
                                            title="Назва"
                                            type="text"
                                            value={data.title}
                                            isStyle={true}
                                            error={error.title}
                                            onChange={(e) => updateKitchenForm("title", e.target.value)}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Місто"
                                            error={error.city_sync_id}
                                            isBlack={true}
                                            width="100%"
                                            onChange={(e) => updateKitchenForm("city_sync_id", e.target.value)}
                                            value={data.city_sync_id}
                                            list={citiesMapped(cities)}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.row_form} ${styles.row_form_flex_end}`}>
                                    <div className={`${styles.item_two}`}>
                                        <InputPhone
                                            title='Номер телефону'
                                            type='text'
                                            isStyle={true}
                                            value={data?.phone}
                                            onChange={(value) => updateKitchenForm("phone", value)}
                                            width="100%"
                                        />
                                    </div>
                                    <div className={`${styles.item_two}`}>
                                        <TextInput
                                            title="Адреса"
                                            error={error.address}
                                            type="text"
                                            value={data.address}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("address", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME}
                                        label="Активність кухні"
                                        valueKey="status"
                                        value={data.status}
                                        onChange={(e) => updateKitchenForm("status", +e.value.value)}
                                    />
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME_PICKUP}
                                        label="Самовивіз ?"
                                        valueKey="pickup"
                                        value={data.pickup}
                                        onChange={(e) => updateKitchenForm("pickup", +e.value.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Години відкриття-закриття кухні </div>
                            <div className="wr-block-content">
                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TimeInput
                                            width="100%"
                                            title="Відкриття"
                                            value={moment(data.time_open || "00:00", "HH:mm")}
                                            error={error.time_open}
                                            isStyle={true}
                                            onChange={(time) => updateKitchenForm("time_open", time.format("HH:mm"))}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TimeInput
                                            width="100%"
                                            title="Закриття"
                                            value={moment(data.time_close || "00:00", "HH:mm")}
                                            error={error.time_open}
                                            isStyle={true}
                                            onChange={(time) => updateKitchenForm("time_close", time.format("HH:mm"))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Кординати </div>
                            <div className="wr-block-content">
                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TextInput
                                            title="Широта"
                                            type="number"
                                            error={error.latitude}
                                            value={data.latitude}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("latitude", e.target.value)}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TextInput
                                            title="Довгота"
                                            type="number"
                                            error={error.longitude}
                                            value={data.longitude}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("longitude", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Фіскальний реєстратор </div>
                            <div className="wr-block-content">
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME_CHANGES}
                                        label="Налаштування по змінах"
                                        valueKey="changes"
                                        value={changes}
                                        onChange={(e) => setChanges(e.value.value)}
                                    />
                                </div>
                                {
                                    (changes == RADIO_SCHEME_CHANGES[0].value) && (
                                        <div className={styles.wr_drop}>
                                            <div className={styles.title_in}>Перша зміна</div>
                                            {
                                                !!shiftFirst?.status && (
                                                    <>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Касир"
                                                                value={shiftFirst.cashier_name}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashier_name", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Номер ПРРО"
                                                                value={shiftFirst.cashalot_numfiscal_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_numfiscal_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Сертифікат в форматі Base64"
                                                                value={shiftFirst.cashalot_certificate_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_certificate_shift", e.target.value)}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in}>Ключи для кашалота</div>
                                                            <TextInput
                                                                title="Приватний ключ для кашалота"
                                                                value={shiftFirst.cashalot_private_key_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_private_key_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Пароль"
                                                                value={shiftFirst.cashalot_password_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_password_shift", e.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <br />
                                            <div className={styles.wr_drop}>
                                                <Radio
                                                    colors={"#000"}
                                                    items={RADIO_SCHEME}
                                                    label="Активність зміни"
                                                    valueKey="status"
                                                    value={shiftFirst?.status}
                                                    onChange={(e) => updateKitchenFormShiftFirst("status", +e.value.value)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (changes == RADIO_SCHEME_CHANGES[1].value) && (
                                        <div className={styles.wr_drop}>
                                            <div className={styles.title_in}>Друга зміна</div>
                                            {
                                                !!shiftLast?.status && (
                                                    <>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Касир"
                                                                value={shiftLast.cashier_name}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashier_name", e.target.value)}
                                                            />
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Номер ПРРО"
                                                                value={shiftLast.cashalot_numfiscal_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_numfiscal_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Сертифікат в форматі Base64"
                                                                value={shiftLast.cashalot_certificate_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_certificate_shift", e.target.value)}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in}>Ключи для кашалота</div>
                                                            <TextInput
                                                                title="Приватний ключ для кашалота"
                                                                value={shiftLast.cashalot_private_key_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_private_key_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Пароль"
                                                                value={shiftLast.cashalot_password_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_password_shift", e.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className={styles.wr_drop}>
                                                <Radio
                                                    colors={"#000"}
                                                    items={RADIO_SCHEME}
                                                    label="Активність зміни"
                                                    valueKey="status"
                                                    value={shiftLast?.status}
                                                    onChange={(e) => updateKitchenFormShiftLast("status", +e.value.value)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className={`${styles.title_in} ${styles.title_in_2}`}>Готівка: (від часу відкриття) + датепікер тільки години (Час до якого працює)</div>

                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cash_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cash_checks_to", hour(e.target.value, data.create_cash_checks_to))}
                                            value={getHour(data.create_cash_checks_to)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cash_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cash_checks_to", minute(e.target.value, data.create_cash_checks_to))}
                                            value={getMinute(data.create_cash_checks_to)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>

                                <div className={`${styles.title_in} ${styles.title_in_2}`}>Безготівка: (від часу відкриття) + датепікер тільки години (Час до якого працює)</div>

                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cashless_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cashless_checks_to", hour(e.target.value, data.create_cashless_checks_to))}
                                            value={getHour(data.create_cashless_checks_to)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cashless_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cashless_checks_to", minute(e.target.value, data.create_cashless_checks_to))}
                                            value={getMinute(data.create_cashless_checks_to)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        {code !== "new" && (
                            <div className={`${styles.blocke_item} wr-block`}>
                                <div className={`${styles.title_in} wr-block-title`}>Примусові cashalot команди </div>
                                <div className="wr-block-content">

                                    {!kitchenShiftLoading && (
                                        <p className={classnames(classes.kitchenShift, { [classes.open]: !!kitchenShiftStatus.shiftId })}>
                                            Поточна зміна: <span>{kitchenShiftStatus.shiftId || "закрита"}</span>
                                        </p>
                                    )}

                                    <div className={styles.row_form}>
                                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                            <ButtonIcon
                                                title={"Відкриття зміни"}
                                                onClick={executeKitchenShiftCashalotCommand.bind(this, data.code, "OpenShift")}
                                                icon={<KeyIcon />}
                                                isLoad={kitchenShiftLoading}
                                                disabled={kitchenShiftLoading}
                                            />
                                        </div>
                                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                            <ButtonIcon
                                                title={"Закриття зміни"}
                                                onClick={executeKitchenShiftCashalotCommand.bind(this, data.code, "CloseShift")}
                                                icon={<KeyIcon />}
                                                isLoad={kitchenShiftLoading}
                                                disabled={kitchenShiftLoading}
                                            />
                                        </div>
                                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                            <ButtonIcon
                                                title={"Cleanup зміни"}
                                                onClick={executeKitchenShiftCashalotCommand.bind(this, data.code, "CleanUp")}
                                                icon={<CleanUpIcon />}
                                                isLoad={kitchenShiftLoading}
                                                disabled={kitchenShiftLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <br />

                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Telegram бот для кухні </div>
                            <div className="wr-block-content">
                                <TextInput
                                    title="Назва бота"
                                    value={data.telegram_bot_name}
                                    isStyle={true}
                                    onChange={(e) => updateKitchenForm("telegram_bot_name", e.target.value)}
                                />
                                <TextInput
                                    title="Токен бота"
                                    value={data.telegram_bot_token}
                                    isStyle={true}
                                    onChange={(e) => updateKitchenForm("telegram_bot_token", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.row_btn}>
                        <div className={styles.item_btn}>
                            <ButtonIcon
                                onClick={handleSubmit}
                                title={titleButton}
                                colorBg="green"
                                icon={<SaveIcon />}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={`${styles.blocke_item} wr-block`}>
                        <div className={`${styles.title_in} wr-block-title`}>Expirenza by mono </div>
                        <div className="wr-block-content">
                            <TextInput
                                title="Resto ID"
                                value={data.resto_id}
                                isStyle={true}
                                onChange={(e) => updateKitchenForm("resto_id", e.target.value)}
                            />
                            <TextInput
                                title="Secret Key"
                                value={data.secret_key}
                                isStyle={true}
                                onChange={(e) => updateKitchenForm("secret_key", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Kitchen.propTypes = {
    data: PropTypes.object,
    cities: PropTypes.array,
    isLoadPage: PropTypes.bool,
    updateKitchenForm: PropTypes.func,
    addKitchenPolygon: PropTypes.func,
    deleteKitchenPolygon: PropTypes.func,
    createKitchen: PropTypes.func,
    updateKitchen: PropTypes.func,
    getKitchen: PropTypes.func,
    getKitchenShiftStatus: PropTypes.func,
    executeKitchenShiftCashalotCommand: PropTypes.func,
    cleaningNewForm: PropTypes.func,
};

export default Kitchen;