import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { isArray } from "lodash";

import {
  updateOrderPayments,
  addOrderPayments,
  removeOrderPayments,
  updateOrderData,
} from "store/actions/order";

import {
  revealBonuses,
  calculateSumPayments,
  calculateSumItems,
  paymentAvailablFunList,
} from "utils/helpers/payment";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export const PaymentsTable = ({
  isEdit,
  isFree = false,
  isUpdatePayment,
}) => {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  // const [oneStart, setOneStart] = useState(false);

  // const [paymentAvailable, setPaymentAvailable] = useState([]);
  // const [paymentAvailableList, setPaymentAvailableList] = useState(0);
  // const [payable, setPayable] = useState(0);
  // const [paid, setPaid] = useState(0);
  // const [paidStar, setPaidStar] = useState(0);

  const {
    order,
    order: {
      data: {
        id,
        payments,
        items,
        restaurant,
        address,
        delivery_type_id: deliveryTypeId,
        ignore_stock
      },
      data
    },
    clients: {
      extensive: {
        wallet: {
          balances
        }
      }
    },
    settings: {
      typesOfDelivery
    }
  } = useSelector(state => state);

  // TODO: refactor with new callcenter
  const [paymentAvailable, paymentAvailableList, payable, paid] = useMemo(() => {
    
    const availablePayments = typesOfDelivery.list.find((el) => el.id == deliveryTypeId).payments;
    const availablePaymentsList = paymentAvailablFunList(availablePayments, payments);

    return [
      // paymentAvailable
      availablePayments,
      // paymentAvailableList
      availablePaymentsList,
      // payable
      (items?.length > 0) ? calculateSumItems({ payments, items, data }) : 0,
      // paid
      (payments?.length > 0) ? calculateSumPayments(payments, availablePayments) : 0,
    ];

  }, [typesOfDelivery, deliveryTypeId, payments, items, address, data]);

  useEffect(() => {
    dispatch(updateOrderData("client_rest", (paid - payable).toFixed(2)));
  }, [paid, payable]);

  const handleAdded = async (item) => {
    let price = 0;

    if (item === undefined) return;

    if (item?.free != 1) {
      let allSum = calculateSumItems({ payments, items, data });
      let sumPaym = calculateSumPayments(payments, paymentAvailable);

      switch (item.code) {
        case "bonus":
          price = revealBonuses(balances, restaurant, allSum);
          break;
        case "rucna-zniska":
          price = 0;
          break;
        default:
          price = allSum - sumPaym;
      }
    }

    if (price < 0)
      price = 0;

    dispatch(addOrderPayments(item.code, price, item.free));

    setOpen(false);
  };

  const renderList = () => {
    return payments.map((item, index) => {
      return (
        <Row key={index} >
          {/* isUpdatePayment={item.payment_type == "online"} */}
          <Cell>
            <MainInMod>
              <RemoveCircleOutlineIcon style={{ height: 18, width: 18 }} />
              <MrMod>
                <CondMob>
                  <Button onClick={() => dispatch(removeOrderPayments(item.payment_type))}>Видалити</Button>
                </CondMob>
              </MrMod>
            </MainInMod>
            {
              paymentAvailable.find((el) => el.code === item.payment_type)?.title
            }
            {" "}
            {
              item.payment_type == "bonus" && (
                `(доступно: ${revealBonuses(balances, restaurant)})`
              )
            }
          </Cell>

          <Cell>
            <Input
              type='number'
              value={item.sum}
              min='0'
              max={item.payment_type == "bonus" ? revealBonuses(balances, restaurant) : "999999"}
              disabled={isFree || (item.payment_type == "bonus" && !!!revealBonuses(balances, restaurant))}
              onChange={(e) =>
                dispatch(updateOrderPayments(item.payment_type, e.target.value))
              }
            />
            <Currency>грн</Currency>
          </Cell>
        </Row>
      );
    });
  };

  const renderAddPayment = () => {
    return paymentAvailableList.map((item, index) => {
      return (
        <Item key={index} onClick={() => handleAdded(item)}>
          {item.title}
        </Item>
      );
    });
  };

  // // TODO: make a function
  // useEffect(() => {
  //   // if (isArray(payments) && payments.length == 0) {
  //   //   handleAdded(paymentAvailableList[0]);
  //   // }

  //   if (false) { // TODO: make sure this is required. | before: payments?.length > 0
  //     let cash = payments.find((el => (el.payment_type == "cash")));

  //     if (cash === undefined)
  //       return;

  //     let sumNum = Number(cash.sum);
  //     let difference = (+payable - +paid);

  //     if (cash?.payment_type) {
  //       let adPreic = (+cash.sum + difference);

  //       // this works only when the amount of the order is greater than the specified amount
  //       if (id && (sumNum < adPreic))
  //         dispatch(updateOrderPayments(cash.payment_type, adPreic));

  //       if (!id)
  //         dispatch(updateOrderPayments(cash.payment_type, adPreic));
  //     }
  //   }
  // }, [items, ignore_stock]);

  // useEffect(() => {
  //   if (oneStart) {

  //     for (let i = 0; i < payments.length; i++) {
  //       let t = payments[i].payment_type;

  //       if ((t == "card" || (t == "glovo") || (t == "bolt"))) {
  //         dispatch(updateOrderPayments(t, payable));
  //         break;
  //       }
  //     }

  //   } else {
  //     setOneStart(true);
  //   }
  // }, [payable, items, payments?.[0]?.payment_type, ignore_stock]);

  // useEffect(() => {
  //   if (payable != 0) {
  //     setPaidStar(payable);
  //   }
  // }, [payable]);

  // -------------------

  return (
    <>
      <Wrapper>
        <Table>
          <Header>
            <Label>Тип оплати</Label>
            <Label>Сума</Label>
          </Header>

          {payments.length > 0 && renderList()}

          {/* поле скільки було сплаченно */}
          {
            !isFree && (
              <Row>
                <Cell>Сплачено:</Cell>
                <Cell>{paid} <Currency>грн</Currency></Cell>
              </Row>
            )
          }

          {/* поле скільки до сплати */}
          {
            !isFree && (
              <Row>
                <Cell>До сплати:</Cell>
                <Cell><b>{payable}</b> <Currency><b>грн</b></Currency></Cell>
              </Row>
            )
          }
        </Table>

        {
          !!paymentAvailableList.length && (
            <>
              <RowBtn>
                <ButtonAdd onClick={() => setOpen(!isOpen)}>Додати оплату +</ButtonAdd>
              </RowBtn>

              <List isOpen={isOpen}>
                <ListWr>
                  <ListMain>
                    {renderAddPayment()}
                  </ListMain>
                  <ListOpas onClick={() => setOpen(!isOpen)} />
                </ListWr>
              </List>
            </>
          )
        }
      </Wrapper>
      {/* {
        isUpdatePayment && (
          <Warn>Якщо тип оплати "Онлайн" то його змінити надалі буде неможливо</Warn>
        )
      } */}
      {
        !!Number(ignore_stock) && (
          <Warn>Дію акцій вимкнуто</Warn>
        )
      }
    </>
  );
};

const Warn = styled.div`
  margin: 10px;
  color: orange;
  font-size: 13px;
`;

const CondMob = styled.div`
  padding: 10px;
  background: #FAFAFA;
  border-radius: 5px;
  box-shadow: 0 0 11px -1px;
`;

const MainInMod = styled.div`
  position: relative;
  svg {
    cursor: pointer;
  }
  &:hover {
    & > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const MrMod = styled.div`
  position: absolute;
  left: 0;
  bottom: 100%;
  padding-bottom: 5px;
  width: 100px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
`;

const ListMain = styled.div`
  position: relative;
  z-index: 10;
  max-height: 150px;
  overflow: auto;
`;

const ListOpas = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`;

const ListWr = styled.div``;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 3px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #cdc9c3;
  box-shadow: 0px 0px 1px #e6e6e6;
`;

const Table = styled.div`
  width: 100%;
  // pointer-events: ${(p) => p.isUpdatePayment ? 'none' : 'auto'};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 8px 8px 0 0;
  /* padding: 0 5px; */
`;

const RowBtn = styled.div`
  border-top: 1px solid #cdc9c3;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // pointer-events: ${(p) => p.isUpdatePayment ? 'none' : 'auto'};
  &:not(:last-child) {
    border-bottom: 1px solid #cdc9c3;
  }
`;

const Label = styled.div`
  border-left: 1px solid #cdc9c3;
  padding: 8px 15px;

  &:last-child {
    width: 200px;
  }

  &:first-child {
    border-left: none;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  border-left: 1px solid #cdc9c3;

  &:first-child {
    border-left: none;
    width: 50%;
  }

  &:last-child {
    width: 200px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding: 0;
  background-color: transparent;
  color: red;

  &:hover {
    cursor: pointer;
    color: #5f76f8;
  }
`;

const ButtonAdd = styled.button`
  padding: 4px;
  margin: 5px;
  color: #5f76f8;
  border-radius: 10px;
  background-color: "transparent";
  font-size: 16px;
  font-family: Rubik;

  &:hover {
    cursor: pointer;
    background-color: #fff;
  }
`;

const List = styled.ul`
  position: absolute;
  bottom: 33px;
  left: 8px;
  z-index: 2;
  display: ${(p) => (p.isOpen ? "flex" : "none")};
  flex-direction: column;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #cdc9c3;
`;

const Item = styled.li`
  padding: 1px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    cursor: pointer;
    color: #5f76f8;
  }
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  width: 80px;
`;

const Currency = styled.span`
  margin-left: auto;
`;