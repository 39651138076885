import { useState } from "react";

import { MenuItem } from "components/menuPageMulti/menuItem";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import styled from "styled-components";
import { get } from "lodash";

// icons
import KitchenIcon from "@mui/icons-material/Kitchen";

// classes & classnames
import classnames from "classnames";
import styles from "../../../../components/menuGeneral/scss/menuPage.module.scss";
import classes from "./LiqpayConfigurator.module.scss";

const LiqpayConfigurator = ({
  // state
  data = {},
  restaurants = [],
  kitchens = [],
  // actions
  updateFormPayment = () => { },
}) => {

  const [kitchen, setKitchen] = useState(kitchens[0]?.code);
  const [restaurant, setRestaurant] = useState(restaurants[0]?.code);

  return (
    <div className={classes.container}>
      <div className={classnames(styles.wr_menu, classes.kitchens)}>
        <ul className={styles.ul}>
          {kitchens.map(({ title, code }) => (
            <li key={code} className={styles.li}>
              <a onClick={setKitchen.bind(this, code)} className={classnames(styles.a, { [styles.active]: code === kitchen })}>
                <span className={styles.icon}>
                  <KitchenIcon />
                </span>
                {title}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Restaurants&Fields */}
      <div className="wr-block-content">
        <MenuItemList>
          {restaurants.map(({ code, name }) => (
            <MenuItem
              key={code}
              inKey={code}
              title={name}
              onHandle={setRestaurant}
              isActive={restaurant === code}
            />
          ))}
        </MenuItemList>
        <div>
          <TextInput
            title="Публічний ключ"
            value={get(data, `liqpay_settings.${kitchen}.${restaurant}.public_key`)}
            isStyle={true}
            onChange={(e) => updateFormPayment(`liqpay_settings.${kitchen}.${restaurant}.public_key`, e.target.value)}
          />
          <TextInput
            title="Приватний ключ"
            value={get(data, `liqpay_settings.${kitchen}.${restaurant}.private_key`)}
            isStyle={true}
            onChange={(e) => updateFormPayment(`liqpay_settings.${kitchen}.${restaurant}.private_key`, e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

const MenuItemList = styled.ul`
    margin-top: 10px;
    display: flex;
`;

export default LiqpayConfigurator;
